<template>
  <div class="jepaas-successful-detail">
    <div class="box_top">
      <Header class="page_hea" />
    </div>
    <div class="page_content">
      <div class="left">
        <div class="directory">
          <div
            v-for="(item, i) in imgList"
            :key="i"
            :class="`${ index == i ? 'active' : '' }`"
            @click.stop.prevent="changeItem(i)"
          >
            {{ item.text }}
          </div>
        </div>
      </div>
      <div
        class="right"
        v-html="active.html"
      />
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

import bg_su from '@/assets/imgs/successful/bg_su.png';
import mock from '@/assets/js/mock';

export default {
  name: 'SuccessfulDetail',
  components: { Header, Footer },
  data() {
    return {
      bg_su,
      imgList: [
        {
          text: '大型金融企业内部办公管理系统',
          html: '',
        },
        {
          text: '长沙某集团采购管理系统',
          html: '',
        },
        {
          text: '集团型能源企业设备管理系统',
          html: '',
        },
        {
          text: '蒜瓣SCRM客户关系管理系统',
          html: '',
        },
        {
          text: '高校数字校园系统应用',
          html: '',
        },
        {
          text: '集团型制造企业信息管理工作系统',
          html: '',
        },
      ],
      active: {},
      index: 0,
    };
  },
  created() {
    this.imgList.forEach((item, index) => {
      item.html = mock.successfulDetail[index];
    });
    this.index = this.$route.query.index || 0;
    // this.index = 3;
    this.active = this.imgList[this.index];
  },
  methods: {
    // 切换
    changeItem(i) {
      if (this.index == i) return;
      this.index = i;
      this.active = this.imgList[this.index];
    },
  },
};
</script>

<style lang="less">
  .jepaas-successful-detail {
    .box_top {
      width: 100%;
      height: 80px;
      background: linear-gradient(
        270deg,
        rgba(252, 103, 81, 1) 0%,
        rgba(246, 71, 77, 1) 100%
      );
    }
    .page_content {
      text-align: center;
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .left {
        width: 260px;
        .directory {
          margin-top: 90px;
          > div {
            font-size: 16px;
            color: #333333;
            height: 60px;
            line-height: 60px;
            text-align: left;
            position: relative;
            padding-left: 10px;
            cursor: pointer;
            &.active {
              &:before {
                content: '';
                width: 3px;
                background: #FF3041;
                position: absolute;
                top: 21px;
                bottom: 23px;
                left: 0;
              }
            }
            &:not(:last-child) {
              border-bottom: 1px solid #dddddd;
            }
          }
        }
      }
      .right {
        width: 880px;
        padding-bottom: 60px;
        > p {
          font-size: 16px;
          color: #666666;
          margin: 0;
          margin-top: 30px;
          text-align: left;
        }
        > .title {
          font-size: 30px;
          color: #333333;
          text-align: center;
          font-weight: bold;
        }
        > .subtitle {
          font-size: 20px;
          color: #333333;
          font-weight: bold;
        }
        > .textCenter {
          text-align: center;
        }
        > img {
          margin-top: 30px;
          width: 100%;
          display: block;
        }
      }
    }
  }
</style>
